define("discourse/plugins/discourse-post-menu-pm-button/discourse/initializers/extend-for-post-menu-pm-button", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-post-menu-pm-button/discourse/components/pm-user-button"], function (_exports, _pluginApi, _pmUserButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'extend-for-post-menu-pm-button',
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        api.registerValueTransformer("post-menu-buttons", _ref => {
          let {
            value: dag,
            context: {
              post
            }
          } = _ref;
          dag.add("pm-user", _pmUserButton.default, {
            before: "reply"
          });
        });
        api.registerValueTransformer("post-menu-buttons", _ref2 => {
          let {
            value: dag,
            context: {
              post,
              buttonKeys
            }
          } = _ref2;
          if (siteSettings.post_menu_pm_button_hide_reply_button_when_pm_button_is_shown && post.show_pm_user_button) {
            dag.delete(buttonKeys.REPLY);
          }
        });
      });
    }
  };
});