define("discourse/plugins/discourse-post-menu-pm-button/discourse/components/pm-user-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/get-owner", "discourse/models/composer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _getOwner, _composer3, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PmUserButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.inject]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static shouldRender(args) {
      return args.post.show_pm_user_button;
    }
    // indicates if the button will be prompty displayed or hidden behind the show more button
    static hidden(args) {
      return false;
    }
    showLabel() {
      return !this.site.isMobileView;
    }
    sendPmToUser() {
      const composerController = (0, _getOwner.getOwnerWithFallback)(this).lookup("controller:composer");
      const postUrl = this.args.post?.url;
      composerController.open({
        action: _composer3.default.PRIVATE_MESSAGE,
        draftKey: _composer3.default.NEW_PRIVATE_MESSAGE_KEY,
        draftSequence: 0,
        archetypeId: "private_message",
        title: I18n.t("post_menu_pm_button.composer.title", {
          title: this.args.post?.topic?.title
        }),
        reply: `${window.location.protocol}//${window.location.host}${postUrl}`,
        recipients: this.args.post.username
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "sendPmToUser", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="reply create fade-out pm-button-plug-in"
          ...attributes
          @action={{this.sendPmToUser}}
          @icon="far-comment-dots"
          @label={{if this.showLabel "post_menu_pm_button.button.label"}}
          @title="post_menu_pm_button.button.title"
        />
      
    */
    {
      "id": "zeyI+xHO",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"reply create fade-out pm-button-plug-in\"],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"sendPmToUser\"]],\"far-comment-dots\",[52,[30,0,[\"showLabel\"]],\"post_menu_pm_button.button.label\"],\"post_menu_pm_button.button.title\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-menu-pm-button/discourse/components/pm-user-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PmUserButton;
});